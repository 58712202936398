<template>
    <aside class="hackathons-list">
        <h5>Recent hackathons</h5>
        <DsListItem
            v-for="hackathon in filteredHackathons"
            :key="hackathon.id"
            border-style="full"
            clickable
            :title="hackathon.name"
            @click="$router.push({ name: 'hackathon', params: { id: hackathon.id } })"
        >
            <template #leading>
                <img
                    v-if="hackathon.thumbnail || hackathon.image"
                    :src="hackathon.thumbnail || hackathon.image"
                    :alt="hackathon.name"
                    class="hackathon-image"
                />

                <div v-else class="project-image-placeholder">
                    <DsIcon name="image" />
                </div>
            </template>

            <template #title>
                {{ hackathon.name }}
            </template>

            <template #description>
                <DsStatus v-if="isActiveHackathon(hackathon)" emphasis="high" type="good">
                    <span>Join now!</span>
                </DsStatus>
            </template>
        </DsListItem>

        <br />
    </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            project: ({ projects }) => projects.project,
            hackathon: ({ hackathons }) => hackathons.hackathon,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        filteredHackathons() {
            return this.hackathons.filter((hackathon) => !hackathon.type || hackathon.type === 'hackathon').slice(0, 3);
        },

        hackathonId() {
            if (this.$route.name === 'project') return this.project.hackathonId;
            if (this.$route.name === 'hackathon') return this.hackathon.id;

            return this.$route.params.id;
        },
    },

    methods: {
        isActiveHackathon(hackathon) {
            return this.activeHackathon?.id === hackathon.id;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.hackathons-list {
    background-color: $color-white;
    border: 1px solid $color-gray-300;
    border-radius: $border-radius;
}

.hackathon-image {
    width: 100px;
    height: auto;
    border-radius: $border-radius;
}

.ribbon {
    @media($extra-small) {
        display: none;
    }

    @media($small) {
        top: 0;
    }

    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
        font-size: 10px;
        font-weight: bold;
        color: $color-green-050;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        background: $color-green-600;
        background: linear-gradient($color-green-700 0%, $color-green-600 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px; right: -21px;
    }
}

h5 {
    padding: $spacing-200;
}
</style>
